import "../index.css";
import ClayLayout from "@clayui/layout";
import { ClayTooltipProvider } from "@clayui/tooltip";
import { getShortText } from "../utils/util";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllCoursesAndFirstLessons } from "../services/course";
const getAudience = (audienceArray) => {
  let audience = "";

  if (audienceArray) {
    audience = audienceArray[0].name;

    if (audienceArray.length > 1) {
      audience += "+" + (audienceArray.length - 1);
    }
  }
  return audience;
};

const getTooltipAudience = (audienceArray) => {
  let audience = "";
  audienceArray.forEach((persona) => {
    audience += persona.name + ", ";
  });
  return audience.slice(0, -2);
};
const CoursePage = () => {
  const [courses, setCourses] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    const fetchCourses = async () => {
      const data = await getAllCoursesAndFirstLessons();

      const courses = data
        .filter((course) => course.lesson && course.r_module_c_course)
        .sort(
          (a, b) => a.r_module_c_course.position - b.r_module_c_course.position
        );

      setCourses(courses);
    };

    fetchCourses();
  }, []);
  return (
    <div className="container-cards-page">
      {courses && courses.length > 0 && (
        <>
          {courses.map((course, index) => {
            return (
              <ClayLayout.Col className="card-course" key={index}>
                <div className="tag-course">
                  <p>{t("course")}</p>
                </div>
                <a
                  href={`/l/${course.lesson[0].id}`}
                  id="course-container-link"
                >
                  <div className="course-container d-flex learn-education__landing-card">
                    <div className="course-content-card d-flex flex-column justify-content-between">
                      <h4 className="title">
                        {course.r_module_c_course.title}
                      </h4>
                      <div className="description">
                        {getShortText(
                          course.r_module_c_course.description,
                          150
                        )}
                      </div>
                      <div
                        className="d-flex information learn-education"
                        key={course.r_module_c_course.id}
                      >
                        <ClayTooltipProvider>
                          <div
                            className="card-tag card-tag__persona"
                            data-tool-tip-align="top"
                            title={getTooltipAudience(
                              course.r_module_c_course.audience
                            )}
                          >
                            <p></p>
                            <span>
                              {getAudience(course.r_module_c_course.audience)}
                            </span>
                          </div>
                        </ClayTooltipProvider>
                        <div
                          className={`card-tag card-tag__expertise-${course.r_module_c_course.level.name.toLowerCase()}`}
                        >
                          <p>{course.r_module_c_course.level.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </ClayLayout.Col>
            );
          })}
        </>
      )}
    </div>
  );
};
export default CoursePage;
