import React from "react";
import { t } from "i18next";

const UserDashboardSectionBanner = ({ section }) => {
  const SectionSvg = ({ section }) => {
    if (section === "inprogress") {
      return (
        <svg
          fill="none"
          height="48"
          viewBox="0 0 48 48"
          width="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="48" height="48" rx="10" fill="#E7EFFF" />
          <path d="M15.75 8.25V39.75" stroke="#0B5FFF" strokeWidth="2" />
          <path
            clipRule="evenodd"
            d="M24 7.5H13.5C11.8431 7.5 10.5 8.84314 10.5 10.5V37.5C10.5 39.1568 11.8431 40.5 13.5 40.5H34.5C36.1568 40.5 37.5 39.1568 37.5 37.5V10.5C37.5 8.84314 36.1568 7.5 34.5 7.5V9C35.3284 9 36 9.67157 36 10.5V37.5C36 38.3284 35.3284 39 34.5 39H13.5C12.6716 39 12 38.3284 12 37.5V10.5C12 9.67157 12.6716 9 13.5 9H24V7.5ZM33.75 9H24.75V7.5H33.75V9Z"
            fill="#0B5FFF"
            fillRule="evenodd"
          />
          <path
            d="M24.75 6.75V30L29.25 27.75L33.75 30V7.5C33.75 7.08579 33.4142 6.75 33 6.75H24.75Z"
            fill="#0B5FFF"
          />
        </svg>
      );
    } else if (section === "completed") {
      return (
        <svg
          fill="none"
          height="48"
          viewBox="0 0 48 48"
          width="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="48" height="48" rx="10" fill="#E7EFFF" />
          <path
            d="M35.7914 11.2594L23.8734 25.1883L18.8391 20.1469C16.793 18.1148 13.6148 21.3 15.6609 23.332L22.4109 30.0891C22.8188 30.4969 23.3813 30.75 24 30.75C24.682 30.75 25.2938 30.4406 25.7086 29.9625L39.2086 14.1844C41.0789 12.0047 37.6617 9.10078 35.7914 11.2594Z"
            fill="#0B5FFF"
          />
          <path
            d="M24 42C14.0719 42 6 33.9281 6 24C6 14.0719 14.0719 6 24 6C25.9195 6 27.8109 6.30234 29.618 6.89297C32.4797 7.82812 31.0242 12.0891 28.2188 11.168C26.8617 10.725 25.4414 10.5 24 10.5C16.5539 10.5 10.5 16.5539 10.5 24C10.5 31.4461 16.5539 37.5 24 37.5C31.4461 37.5 37.5 31.4461 37.5 24C37.5 23.4867 37.4719 22.9875 37.4156 22.4953C37.2398 19.4438 41.5219 19.1344 41.8875 22.0031C41.9578 22.657 42 23.325 42 24.007C42 33.9281 33.9281 42 24 42Z"
            fill="#0B5FFF"
          />
        </svg>
      );
    }
    return (
      <svg
        fill="none"
        height="48"
        viewBox="0 0 48 48"
        width="48"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="48" height="48" rx="10" fill="#E7EFFF" />
        <path
          clipRule="evenodd"
          d="M39.7499 11.1273C39.7535 10.6655 39.6232 10.2124 39.3746 9.82219C39.1261 9.43197 38.7697 9.1211 38.3482 8.92671C33.8277 6.95332 28.9372 5.95625 24 6.00147C19.0628 5.95625 14.1722 6.95332 9.65181 8.92671C9.23026 9.1211 8.87391 9.43197 8.62535 9.82219C8.37679 10.2124 8.24651 10.6655 8.25007 11.1273V23.0802C8.24371 26.9587 9.41779 30.7483 11.6181 33.9516C13.8184 37.1549 16.9422 39.6221 20.5791 41.0292L22.2596 41.6772C23.3794 42.1076 24.6205 42.1076 25.7404 41.6772L27.4209 41.0292C31.0578 39.6221 34.1815 37.1549 36.3819 33.9516C38.5822 30.7483 39.7563 26.9587 39.7499 23.0802V11.1273ZM35.4581 17.9721C35.8682 17.5326 36.0083 17.0365 35.944 16.5677C35.8806 16.1052 35.6215 15.6885 35.2686 15.3875C34.9155 15.0864 34.4554 14.8896 33.9725 14.8823C33.4837 14.8749 32.9868 15.0624 32.5763 15.5032L32.5745 15.5052L22.1706 26.9343L16.8397 21.8242C16.0154 21.0038 14.872 21.2287 14.1882 21.8374C13.8427 22.145 13.5921 22.5656 13.5459 23.0332C13.499 23.5076 13.6654 24.0044 14.1062 24.4465L20.8935 30.9514C21.256 31.2986 21.7513 31.4911 22.2575 31.4911L22.3139 31.491C22.8385 31.4734 23.3397 31.2516 23.6953 30.87L23.697 30.8681L35.4581 17.9721Z"
          fill="#0B5FFF"
          fillRule="evenodd"
        />
      </svg>
    );
  };

  return (
    <div className="section-container">
      <div className="section-banner">
        <div className="banner-icon mr-4">
          <SectionSvg section={section} />
        </div>

        <div className="banner-title">
          <h2>{t(`dashboard-label-${section}-title`)}</h2>
          <p>{t(`dashboard-label-${section}-description`)}</p>
        </div>
      </div>
    </div>
  );
};

export default UserDashboardSectionBanner;
