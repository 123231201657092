import ClayButton from "@clayui/button";
import ClayModal from "@clayui/modal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserEnrollmentByCourseId, updateUserEnrollmentByEnrollmentId } from "../../services/enrollment";

const UserDashboardCloseModal = ({
  observer,
  onOpenChange,
  courseId,
  courseName,
}) => {
  const [enrollmentId, setEnrollmentId] = useState(null)

  const { t } = useTranslation();

  useEffect(() => {
    if (courseId) {
      const fetchUserEnrollment = async () => {
        const result = await getUserEnrollmentByCourseId(courseId);

        setEnrollmentId(result.items[0].id)
      };

      fetchUserEnrollment();
    }
  }, [courseId]);


  const removeCourseFromInProgressList = async () => {
    if (enrollmentId) {
      await updateUserEnrollmentByEnrollmentId(enrollmentId, false)

      await onOpenChange(false)
  
      window.location.reload()
    }
  }

  return (
    <>
      <ClayModal observer={observer} size="md" center>
        <ClayModal.Header>{t("remove-in-progress-course")}</ClayModal.Header>
        <ClayModal.Body>
          <p>
            <span>{courseName}</span>
            {` ${t(
              "course-progress-will-be-reset-and-it-will-no-longer-appear-in-your-in-progress-courses"
            )}`}
          </p>
          <p>
            <span>{t("this-action-can-not-be-undone")}</span>
          </p>
        </ClayModal.Body>
        <ClayModal.Footer
          last={
            <ClayButton.Group spaced>
              <ClayButton
                displayType="secondary"
                onClick={() => onOpenChange(false)}
              >
                {t("cancel")}
              </ClayButton>
              <ClayButton
                className="modal-button__primary"
                onClick={removeCourseFromInProgressList}
              >
                {t("remove-course")}
              </ClayButton>
            </ClayButton.Group>
          }
        />
      </ClayModal>
    </>
  );
};

export default UserDashboardCloseModal;