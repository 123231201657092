import { request } from "../utils/request";
import { config } from "../utils/constants";
import { getCurrentSiteId, getDPTAssetId } from "../utils/util";

export const getCurrentCertification = async () => {
  const data = await request({
    url: `${config.certificationEndpoint}${getDPTAssetId()}`,
    method: "get",
  });
  return data;
};

export const getCertificationsList = async () => {
  const data = await request({
    url: `${config.certificationEndpoint}scopes/${getCurrentSiteId()}`,
    method: "get",
  });
  return data.items;
};

export const getAssessments = async () => {
  const data = await request({
    url: `${config.certificationEndpoint}${getDPTAssetId()}/p2S3CertificationToP2S3Assessments?fields=id%2Ctitle%2Cdescription%2CuRL%2CdurationMinutes`,
    method: "get",
  });
  return data.items
}

export const getLearningPathsIdFromAssessments = async () => {
  const data = await request({
    url: `${config.learningPathsEndpoint}scopes/guest?fields=id%2Cr_p2S3AssessmentToP2S3LearningPaths_c_p2s3AssessmentId`,
    method: "get",
  });
  return data.items
}