/*global Liferay*/

import {forwardRef, useCallback, useEffect, useState} from "react";
import {getLessonById} from "../services/course";
import {getCurrentLanguageKey, getDPTAssetId, showError} from "../utils/util";
import ClayLoadingIndicator from "@clayui/loading-indicator";
import {Text} from "@clayui/core";
import ClayTabs from "@clayui/tabs";
import LessonAttachmentsView from "./LessonAttachmentsView";
import { useTranslation } from "react-i18next";
import SignIn from "./SignIn";


const LessonView = forwardRef((props, ref) => {

    const {height, videoHeight, videoWidth,lessonId} = props;

    const { t, i18n: {changeLanguage, language} } = useTranslation();

    const [active, setActive] = useState(null);
    const [assetId, setAssetId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLesson, setSelectedLesson] = useState(0);

    const loadLesson = useCallback(() => {

        if (assetId) {

            setIsLoading(true);

            getLessonById(assetId).then(result => {

                setSelectedLesson(result);

                setIsLoading(false);


            }, error => {

                setIsLoading(false);

                showError(t('error'),t('error-widget-placement'))

            }).catch(error => {

                setIsLoading(false);

            })

        }

    }, [assetId])

    useEffect(()=>{
        setActive(0);
    },[selectedLesson])

    useEffect(() => {

        if (assetId && assetId.toString().length > 0){
            loadLesson();
        }

    }, [assetId])


    useEffect(() => {

        if (lessonId && lessonId !== ""){

            setAssetId(lessonId)

        }else{

            setAssetId(getDPTAssetId());

        }

        changeLanguage(getCurrentLanguageKey());

    }, []);

    return (
        <>
            <div style={{minHeight: height, position: "relative", overflow: "hidden"}}>

                <div className="main-container">
                    {selectedLesson && (
                        <div className="w-100 h-100">
                            <ClayTabs active={active} onActiveChange={setActive}>
                                {selectedLesson.media && selectedLesson.media.link && selectedLesson.media.link.href && (
                                    <ClayTabs.Item
                                        className="mx-4"
                                        innerprops={{
                                            "aria-controls": "tabpanel-1"
                                        }}
                                    >
                                        {t('lesson')}
                                    </ClayTabs.Item>)}
                                <ClayTabs.Item

                                    innerprops={{
                                        "aria-controls": "tabpanel-2"
                                    }}
                                >
                                    {t('description')}
                                </ClayTabs.Item>
                                <ClayTabs.Item
                                    innerprops={{
                                        "aria-controls": "tabpanel-3"
                                    }}
                                >
                                    {t('attachments')}
                                </ClayTabs.Item>
                            </ClayTabs>
                            <ClayTabs.Content activeIndex={active} fade>
                                {selectedLesson.media && selectedLesson.media.link && selectedLesson.media.link.href && (
                                    <ClayTabs.TabPane aria-labelledby="tab-1">
                                        <div className="px-4">
                                            <div className="mb-3">
                                                <Text size={8}>
                                                    {selectedLesson.title}
                                                </Text>
                                            </div>
                                            <div className="w-100 d-flex">
                                                <video className="m-auto" width={videoWidth} height={videoHeight}
                                                       key={selectedLesson.id} controls>
                                                    <source src={selectedLesson.media.link.href} type="video/mp4"/>
                                                    {t('video-error')}
                                                </video>
                                            </div>
                                        </div>

                                    </ClayTabs.TabPane>
                                )}
                                <ClayTabs.TabPane aria-labelledby="tab-2">
                                    <div className="px-4">
                                        <div dangerouslySetInnerHTML={{__html: selectedLesson.content}}>
                                        </div>
                                    </div>
                                </ClayTabs.TabPane>
                                <ClayTabs.TabPane aria-labelledby="tab-2">
                                    <div className="px-4">
                                        <LessonAttachmentsView
                                            lessonId={selectedLesson.id}></LessonAttachmentsView>
                                    </div>
                                </ClayTabs.TabPane>
                            </ClayTabs.Content>


                        </div>
                    )}
                </div>

                {isLoading && (
                    <ClayLoadingIndicator displayType="secondary" size="sm"/>
                )}

            </div>
        </>
    );

});

export default LessonView;
