/*global Liferay*/

import {forwardRef, useCallback, useEffect, useState} from "react";
import ClayEmptyState from "@clayui/empty-state";
import {getExercisesByModuleId, getLessonByModuleId, getModulesByCourseId} from "../services/course";
import ClayList from "@clayui/list";
import {getCurrentLanguageKey, getDPTAssetId} from "../utils/util";
import ClayLoadingIndicator from "@clayui/loading-indicator";
import {Icon, VerticalBar, Text} from "@clayui/core";
import ClayBadge from "@clayui/badge";
import ClayTabs from "@clayui/tabs";
import ClayButton from "@clayui/button";
import LessonAttachmentsView from "./LessonAttachmentsView";
import {useTranslation} from "react-i18next";
import {ViewSelectedType} from "../utils/constants";
import ExerciseView from "./Quiz/ExerciseView";
import SignIn from "./SignIn";


const CourseView = forwardRef((props, ref) => {

    const {height, videoHeight, videoWidth, courseId} = props;

    const {t, i18n: {changeLanguage, language}} = useTranslation();

    const [active, setActive] = useState(0);
    const [assetId, setAssetId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lessons, setLessons] = useState(null);
    const [exercises, setExercises] = useState(null);
    const [modules, setModules] = useState(null);
    const [selectedLesson, setSelectedLesson] = useState(0);
    const [selectedExercise, setSelectedExercise] = useState(0);
    const [selectedModule, setSelectedModule] = useState(0);
    const [selectedType, setSelectedType] = useState(ViewSelectedType.Lesson)

    const loadCourseModules = () => {

        if (assetId) {

            setIsLoading(true);

            getModulesByCourseId(assetId).then(result => {

                let modules = result.items.sort((a, b) => a.position - b.position);
                setModules(modules);

                setIsLoading(false);

                setSelectedModule(modules[0].id)

            }, error => {

                setIsLoading(false);

            }).catch(error => {

                setIsLoading(false);

            })
        }

    }

    const loadModuleLessons = useCallback(() => {

        if (selectedModule) {

            setIsLoading(true);

            getLessonByModuleId(selectedModule).then(result => {

                setLessons(result.items.sort((a, b) => a.position - b.position));

                setIsLoading(false);


            }, error => {

                setLessons([]);
                setIsLoading(false);

            }).catch(error => {

                setLessons([]);
                setIsLoading(false);

            })

        }

    }, [selectedModule])

    const loadModuleExercises = useCallback(() => {

        if (selectedModule) {

            setIsLoading(true);

            getExercisesByModuleId(selectedModule).then(result => {

                setExercises(result.items.sort((a, b) => a.position - b.position));

                setIsLoading(false);


            }, error => {

                setLessons([]);
                setIsLoading(false);

            }).catch(error => {

                setLessons([]);
                setIsLoading(false);

            })

        }

    }, [selectedModule])


    useEffect(() => {

        if (lessons && !lessons[selectedLesson].media)
            setActive(1);


    }, [selectedLesson, lessons])

    useEffect(() => {

        loadModuleLessons();

        loadModuleExercises();

    }, [selectedModule])

    useEffect(() => {

        loadCourseModules();

    }, [assetId])

    useEffect(() => {

        changeLanguage(getCurrentLanguageKey());

        if (courseId && courseId.toString().length > 0) {

            setAssetId(courseId);

        } else {

            setAssetId(getDPTAssetId());

        }


    }, [changeLanguage]);

    return (
        <>
            <div style={{minHeight: height, position: "relative", overflow: "hidden"}}>
                <VerticalBar
                    absolute
                    className="side-menu"
                    position="left"
                >
                    <VerticalBar.Content>
                        <VerticalBar.Panel key='lessons'>
                            <div className="sidebar-header" style={{position: "relative", height: "100%"}}>
                                <div className="component-title">{t('lessons')}</div>
                                <div className="component-body"
                                     style={{position: "relative", height: "100%", overflowY: "scroll"}}>

                                    {lessons && (
                                        <ClayList>

                                            {lessons && lessons.length > 0 && lessons.map((lesson, index) => {
                                                return <ClayList.Item flex key={lesson.id} className="my-2">
                                                    <ClayList.ItemField>
                                                        <ClayButton
                                                            displayType={selectedLesson === index ? 'primary' : 'secondary'}
                                                            onClick={() => {
                                                                setSelectedType(ViewSelectedType.Lesson);
                                                                setSelectedLesson(index)
                                                            }}>
                                                            {index + 1}
                                                        </ClayButton>
                                                    </ClayList.ItemField>

                                                    <ClayList.ItemField expand>
                                                        <ClayList.ItemTitle>
                                                            {lesson.title}
                                                        </ClayList.ItemTitle>
                                                        <ClayList.ItemText>
                                                            <Text size={3} truncate>
                                                                {lesson.description}
                                                            </Text>
                                                        </ClayList.ItemText>
                                                    </ClayList.ItemField>
                                                </ClayList.Item>

                                            })}

                                        </ClayList>

                                    )}

                                    {lessons && lessons.length === 0 && (
                                        <ClayEmptyState
                                            title={t('module-has-no-lessons-label')}
                                            imgSrc={`${Liferay.ThemeDisplay.getPathThemeImages()}/states/search_state.gif`}
                                            description={t('module-has-no-lessons-desc')}
                                        />
                                    )}
                                </div>
                            </div>
                        </VerticalBar.Panel>
                        <VerticalBar.Panel key='exercises'>
                            <div className="sidebar-header" style={{position: "relative", height: "100%"}}>
                                <div className="component-title">{t('exercises')}</div>
                                <div className="component-body"
                                     style={{position: "relative", height: "100%", overflowY: "scroll"}}>

                                    {exercises && (
                                        <ClayList>

                                            {exercises && exercises.length > 0 && exercises.map((exercise, index) => {
                                                return <ClayList.Item flex key={exercise.id} className="my-2">
                                                    <ClayList.ItemField>
                                                        <ClayButton
                                                            displayType={selectedLesson === index ? 'primary' : 'secondary'}
                                                            onClick={() => {
                                                                setSelectedType(ViewSelectedType.Exercise);
                                                                setSelectedExercise(exercise.id)
                                                            }}>
                                                            {index + 1}
                                                        </ClayButton>
                                                    </ClayList.ItemField>

                                                    <ClayList.ItemField expand>
                                                        <ClayList.ItemTitle>
                                                            {exercise.title}
                                                        </ClayList.ItemTitle>
                                                        <ClayList.ItemText>
                                                            <Text size={3} truncate>
                                                                {exercise.description}
                                                            </Text>
                                                        </ClayList.ItemText>
                                                    </ClayList.ItemField>
                                                </ClayList.Item>

                                            })}

                                        </ClayList>

                                    )}

                                    {exercises && exercises.length === 0 && (
                                        <ClayEmptyState
                                            title={t('module-has-no-exercises-label')}
                                            imgSrc={`${Liferay.ThemeDisplay.getPathThemeImages()}/states/search_state.gif`}
                                            description={t('module-has-no-exercises-desc')}
                                        />
                                    )}
                                </div>
                            </div>

                        </VerticalBar.Panel>
                    </VerticalBar.Content>

                    <VerticalBar.Bar displayType="light">
                        <VerticalBar.Item key="lessons">
                            <ClayButton
                                aria-label={t('show-lessons')}
                                displayType={"unstyled"}
                            >
                                <Icon symbol="books"/>
                            </ClayButton>
                        </VerticalBar.Item>
                        <VerticalBar.Item key="exercises" expand={true}>
                            <ClayButton
                                aria-label={t('show-exercises')}
                                displayType={"unstyled"}
                            >
                                <Icon symbol="pencil"/>
                            </ClayButton>
                        </VerticalBar.Item>
                    </VerticalBar.Bar>

                </VerticalBar>
                {selectedType === ViewSelectedType.Lesson && (
                    <div className="main-container">
                        {lessons && lessons.length > 0 && (
                            <div className="w-100 h-100">

                                <ClayTabs active={active} onActiveChange={setActive}>

                                    {lessons[selectedLesson].media && lessons[selectedLesson].media.link && (
                                        <ClayTabs.Item
                                            className="mx-4"
                                            innerprops={{
                                                "aria-controls": "tabpanel-1"
                                            }}
                                        >
                                            {t('lesson')}
                                        </ClayTabs.Item>
                                    )}

                                    <ClayTabs.Item
                                        className="mx-4"
                                        innerprops={{
                                            "aria-controls": "tabpanel-2"
                                        }}
                                    >
                                        {t('description')}
                                    </ClayTabs.Item>
                                    <ClayTabs.Item
                                        className="mx-4"
                                        innerprops={{
                                            "aria-controls": "tabpanel-3"
                                        }}
                                    >
                                        {t('attachments')}
                                    </ClayTabs.Item>
                                </ClayTabs>
                                <ClayTabs.Content activeIndex={active} fade>
                                    {lessons[selectedLesson].media && lessons[selectedLesson].media.link && (
                                        <ClayTabs.TabPane aria-labelledby="tab-1">
                                            <div className="px-4">
                                                <div className="mb-3">
                                                    <Text size={8}>
                                                        {lessons[selectedLesson].title}
                                                    </Text>
                                                </div>
                                                <div className="w-100 d-flex">
                                                    <video className="m-auto" width={videoWidth} height={videoHeight}
                                                           key={lessons[selectedLesson].id} controls>
                                                        <source src={lessons[selectedLesson].media.link.href}
                                                                type="video/mp4"/>
                                                        {t('video-error')}
                                                    </video>
                                                </div>
                                            </div>

                                        </ClayTabs.TabPane>
                                    )}
                                    <ClayTabs.TabPane aria-labelledby="tab-2">
                                        <div className="px-4">
                                            <div dangerouslySetInnerHTML={{__html: lessons[selectedLesson].content}}>
                                            </div>
                                        </div>
                                    </ClayTabs.TabPane>
                                    <ClayTabs.TabPane aria-labelledby="tab-2">
                                        <div className="px-4">
                                            <LessonAttachmentsView
                                                lessonId={lessons[selectedLesson].id}></LessonAttachmentsView>
                                        </div>
                                    </ClayTabs.TabPane>
                                </ClayTabs.Content>


                            </div>
                        )}
                        {(lessons && lessons.length === 0) && (
                            <ClayEmptyState
                                title={t('module-has-no-lessons-label')}
                                imgSrc={`${Liferay.ThemeDisplay.getPathThemeImages()}/states/search_state.gif`}
                                description={t('module-has-no-lessons-desc')}
                            />
                        )}
                    </div>
                )}

                {selectedType === ViewSelectedType.Exercise && (
                    <div className="p-4 px-6">
                        <ExerciseView exerciseId={selectedExercise} key={selectedExercise}
                                      height={"100%"}></ExerciseView>
                    </div>
                )}


                <VerticalBar
                    absolute
                    className="side-menu"
                    position="right"
                >
                    <VerticalBar.Content>
                        <VerticalBar.Panel key='lessons'>
                            <div className="sidebar-header" style={{position: "relative", height: "100%"}}>
                                <div className="component-title">{t('modules')}</div>
                                <div className="component-body"
                                     style={{position: "relative", height: "100%", overflowY: "scroll"}}>
                                    {modules && modules.map((module, index) => {
                                        return <ClayList.Item key={module.id} className="my-2" flex>

                                            <ClayList.ItemField>
                                                <ClayButton
                                                    displayType={selectedModule === module.id ? 'primary' : 'secondary'}
                                                    onClick={() => {
                                                        setSelectedModule(module.id);
                                                    }}>
                                                    {index + 1}
                                                </ClayButton>
                                            </ClayList.ItemField>

                                            <ClayList.ItemField expand>
                                                <ClayList.ItemTitle>
                                                    {module.title}
                                                </ClayList.ItemTitle>
                                                <ClayList.ItemText>
                                                    <Text truncate size={3}>
                                                        {module.description}
                                                    </Text>
                                                </ClayList.ItemText>
                                            </ClayList.ItemField>

                                            <ClayList.ItemField aria-label={t('lessons')} className="h-100">
                                                <ClayBadge label={`Lessons: ${module.lessons}`}/>
                                            </ClayList.ItemField>
                                        </ClayList.Item>
                                    })}
                                </div>
                            </div>
                        </VerticalBar.Panel>
                    </VerticalBar.Content>

                    <VerticalBar.Bar displayType="light">
                        <VerticalBar.Item key="lessons" expand={true}>
                            <ClayButton
                                aria-label={t('show-modules')}
                                displayType={"unstyled"}
                            >
                                <Icon symbol="book"/>
                            </ClayButton>
                        </VerticalBar.Item>
                    </VerticalBar.Bar>

                </VerticalBar>
                {isLoading && (
                    <ClayLoadingIndicator displayType="secondary" size="sm"/>
                )}

                {(!selectedModule) && (
                    <ClayEmptyState
                        title={t('no-modules-selected-label')}
                        imgSrc={`${Liferay.ThemeDisplay.getPathThemeImages()}/states/search_state.gif`}
                        description={t('no-modules-selected-desc')}
                    />
                )}
            </div>
        </>

    );

});

export default CourseView;
