import React from "react";
import { t } from "i18next";
import ClayIcon from "@clayui/icon";

const UserDashboardEmptySection = ({ section }) => {
  return (
    <div className="empty-section-card mb-6">
      <h3>{t(`dashboard-label-${section}-header`)}</h3>
      <p>{t(`dashboard-label-${section}-body`)}</p>
      <a className="card-cta-button" href="/education/courses">
        {t("explore-courses")}
        <ClayIcon className="ml-2" symbol="order-arrow-right" />
      </a>
    </div>
  );
};

export default UserDashboardEmptySection;
