import ClayProgressBar from "@clayui/progress-bar";
import React from "react";
import { useTranslation } from "react-i18next";

const QuizProgressBar = ({active, questions, progress}) => {
  const { t } = useTranslation();

  return (
    <div className={"progressbar-container"}>
      <div className={"progressbar-tag"}>
        {`${t("progress-lable")} ${active + 1} ${t("of")} ${questions.length}`}
      </div>
      <ClayProgressBar value={progress} />
    </div>
  );
};

export default QuizProgressBar;
