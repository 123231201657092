import { getShortText } from "../../utils/util";
import ClayProgressBar from "@clayui/progress-bar";
import ClayIcon from "@clayui/icon";
import { ClayButtonWithIcon } from "@clayui/button";
import { useModal } from "@clayui/modal";
import UserDashboardCloseModal from "./UserDashboardCloseModal";
import { useState } from "react";

const UserDashboardCourseCard = ({ userEnrollments }) => {
  const { onOpenChange, open, observer } = useModal();
  const [selectedCourse, setSelectedCourse] = useState({ id: null, name: "" });

  const openModal = (event, courseId, courseName) => {
    event.preventDefault();
    setSelectedCourse({ id: courseId, name: courseName });
    onOpenChange(true);
  };

  return (
    <div className="mb-6 section-cards">
      {userEnrollments.map((item) => {
        return (
          <a
            href={`/l/${item.firstLesson}`}
            key={item.id}
            className="section-card"
          >
            <div className="d-flex flex-column h-100 justify-content-between">
              <div>
                <div className="d-flex">
                  <h3>{item.title}</h3>
                  {open && selectedCourse.id === item.id && (
                    <UserDashboardCloseModal
                      courseId={selectedCourse.id}
                      courseName={selectedCourse.name}
                      onOpenChange={onOpenChange}
                      observer={observer}
                    />
                  )}
                  {!item.courseComplete && (
                    <ClayButtonWithIcon
                      aria-label="Close"
                      symbol="times"
                      title="Close"
                      className="card-button__close"
                      borderless
                      onClick={(e) => openModal(e, item.id, item.title)}
                    >
                      x
                    </ClayButtonWithIcon>
                  )}
                </div>
                <p>{getShortText(item.description)}</p>
              </div>
              {item.courseComplete ? (
                <p className="align-self-start completed-tag">
                  Completed
                  <ClayIcon className="ml-2" symbol="check" />
                </p>
              ) : (
                <ClayProgressBar value={item.courseProgress} className="mt-3" />
              )}
            </div>
          </a>
        );
      })}
    </div>
  );
};

export default UserDashboardCourseCard;