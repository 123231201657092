import ClayForm, {
  ClayCheckbox,
  ClayInput,
  ClayRadio,
  ClayRadioGroup,
} from "@clayui/form";
import ClayTabs from "@clayui/tabs";
import ClayButton from "@clayui/button";
import { t } from "i18next";
import React from "react";
import { getSignInPath, isSignedIn } from "../../utils/util";
import ClayIcon from "@clayui/icon";
import { Text } from "@clayui/core";
import ClayLoadingIndicator from "@clayui/loading-indicator";

const QuizQuestions = ({
  active,
  questions,
  answers,
  parseAnswers,
  setAnswers,
  height,
  handleSubmit,
  isLoading,
  enableBackButton,
  handlePreviousQuestion,
  handleNextQuestion,
}) => {
  return (
    <ClayTabs active={active}>
      <ClayTabs.Content activeIndex={active} className={"w-100"} fade>
        {questions &&
          parseAnswers(answers) &&
          questions.map((question, index) => (
            <ClayTabs.TabPane aria-labelledby="tab-1" key={index}>
              <div className="question">
                <div className="question-header">
                  <span className="component-title">
                    {t("question")} {index + 1}
                  </span>
                </div>
                <div style={{ minHeight: height }} className="question-body">
                  <div className="mb-4">
                    <Text>{question.title}</Text>
                  </div>
                  <div className="mb-3">
                    {question.answers &&
                      question.type === "selectSingleChoice" && (
                        <ClayRadioGroup
                          key={question.id}
                          value={parseAnswers(answers)[question.id]}
                          onChange={(value) => {
                            setAnswers((prevAnswers) => {
                              let newAnswers = {
                                ...JSON.parse(prevAnswers),
                              };
                              newAnswers[question.id] = value;

                              return JSON.stringify(newAnswers);
                            });
                          }}
                        >
                          {question.answers.map((answer, index) => (
                            <ClayRadio
                              key={answer.id}
                              label={answer.title}
                              value={answer.id}
                            />
                          ))}
                        </ClayRadioGroup>
                      )}

                    {question.answers &&
                      question.type === "selectMultipleChoice" &&
                      question.answers.map((answer, index) => (
                        <div className="mb-3">
                          <ClayCheckbox
                            key={answer.id}
                            label={answer.title}
                            onChange={(value) => {
                              setAnswers((prevAnswers) => {
                                let newAnswers = {
                                  ...JSON.parse(prevAnswers),
                                };
                                newAnswers[question.id][answer.id] =
                                  value.target.checked;
                                return JSON.stringify(newAnswers);
                              });
                            }}
                            checked={
                              parseAnswers(answers)[question.id][answer.id]
                            }
                          />
                        </div>
                      ))}

                    {question.type === "freeText" && (
                      <div className="mb-3">
                        <ClayForm.Group>
                          <label htmlFor={`text_${question.id}`}>
                            {t("your-answer")}
                          </label>
                          <ClayInput
                            key={question.id}
                            id={`text_${question.id}`}
                            component="textarea"
                            placeholder={t("insert-your-answer-here")}
                            type="text"
                            value={parseAnswers(answers)[question.id]}
                            onChange={(event) => {
                              setAnswers((prevAnswers) => {
                                let newAnswers = {
                                  ...parseAnswers(prevAnswers),
                                };
                                newAnswers[question.id] = event.target.value;
                                return JSON.stringify(newAnswers);
                              });
                            }}
                          />
                        </ClayForm.Group>
                      </div>
                    )}
                  </div>
                </div>

                <div className="question-footer mb-3 pb-3">
                  <span>
                    {!isSignedIn() && (
                      <>
                        <a href={getSignInPath()}>{t("sign-in")}</a>{" "}
                        {t("quiz-sign-in-text")}
                      </>
                    )}
                  </span>

                  {enableBackButton && (
                    <ClayButton
                      disabled={isLoading}
                      displayType={"secondary"}
                      onClick={handlePreviousQuestion}
                    >
                      {t("back")}
                    </ClayButton>
                  )}

                  {active === questions.length - 1 && (
                    <ClayButton
                      disabled={isLoading}
                      displayType={"primary"}
                      onClick={handleSubmit}
                    >
                      {t("submit")}
                      {isLoading && (
                        <span className="inline-item inline-item-before mx-2">
                          <ClayLoadingIndicator
                            displayType="secondary"
                            size="sm"
                          />
                        </span>
                      )}
                    </ClayButton>
                  )}

                  {active < questions.length - 1 && (
                    <ClayButton
                      displayType={"primary"}
                      onClick={handleNextQuestion}
                    >
                      <span>{t("next")} </span>
                      <ClayIcon symbol={"order-arrow-right"} />
                    </ClayButton>
                  )}
                </div>
              </div>
            </ClayTabs.TabPane>
          ))}
      </ClayTabs.Content>
    </ClayTabs>
  );
};

export default QuizQuestions;
