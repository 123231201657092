import {forwardRef, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {getCurrentLanguageKey, getDPTAssetId, isSignedIn} from "../utils/util";

const UserProgressReport = forwardRef((props, ref) => {


    const {assetId,assetType,assetProgress,viewMode} = props;

    const {t, i18n: {changeLanguage, language}} = useTranslation();



    useEffect(() => {

        changeLanguage(getCurrentLanguageKey());

    }, []);

    return (<>
        {viewMode !== "view" && (
            <div className="bg-light p-2">
                {t('report-progress')}
            </div>
        )}
    </>)

});

export default UserProgressReport;
