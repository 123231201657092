import { useState, useEffect } from "react";
import { getCurrentUserId } from "../../utils/util";
import { getBadgeById, getUserBadgesByUserId } from "../../services/badge";
import { getCourseInfoByCourseId, getCourseByAssetTypeAndId } from "../../services/course";
import { extractYearAndMonth } from "../../utils/util";

const useLinkedInProfile = () => {
  const [profileUrls, setProfileUrls] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = await getCurrentUserId();
        const data = await getUserBadgesByUserId(userId);

        const userBadgesList = await Promise.all(
          data.items.map(async (item) => {
            const badgeCourse = await getCourseByAssetTypeAndId("quiz", item.quizId);
            const userBadge = await getBadgeById(item.badgeId);
            return {
              badgeId: item.badgeId,
              courseId: badgeCourse,
              dateCreated: item.dateCreated,
              courseTitle: (await getCourseInfoByCourseId(badgeCourse)).name,
              title: userBadge.title,
            };
          })
        );

        const urls = userBadgesList.map((badge) => {
          const { year, month } = extractYearAndMonth(badge.dateCreated);
          const title = badge.courseTitle;
          return `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${encodeURIComponent(
            title
          )}&organizationName=Liferay&issueYear=${year}&issueMonth=${month}&expirationYear=&expirationMonth=&certUrl=&certId=`;
        });
        setProfileUrls(urls);
      } catch (err) {
        console.error(`Error while processing`, err);
      } 
    };
    fetchData();
  }, []);
  return { profileUrls };
};
export default useLinkedInProfile;