import {getServerUrl, oAuthRequest, request} from "../utils/request";
import {config} from "../utils/constants";
import {getCurrentSiteId} from "../utils/util";


export const loadExerciseQuestions = (exerciseId) =>{

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.exerciseEndPoint}${exerciseId}/questions`,
        method:'get'
    });

}

export const getQuiz = (exerciseId) =>{

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.exerciseEndPoint}${exerciseId}`,
        method:'get'
    });

}

export const postExerciseResult = async (exerciseId,answersData) =>{

    let serviceBaseAddress = getServerUrl();

    return await request({
        url:`${serviceBaseAddress}${config.exerciseEndPoint}${exerciseId}/result`,
        method:'post',
        data:{
            answers:answersData,
            scopeId : getCurrentSiteId()
        }
    });
}

