import ClayLayout from "@clayui/layout";
import useLinkedInProfile from "../Hooks/UseLinkedInButton";
const UserDashboardBadgeCard = ({ userBadges }) => {
  const { profileUrls } = useLinkedInProfile();
  return (
    <ClayLayout.ContainerFluid>
      <ClayLayout.Row className="gap-3">
        {userBadges.map((badge, index) => (
          <ClayLayout.Col className="d-flex flex-column p-0" key={index}>
            <div className="badge-card">
              <div>
                <div className="badge-img-container mb-3">
                  <img alt={badge.title} src={badge.image}></img>
                </div>
                <h3>{badge.title}</h3>
              </div>
              <p>{badge.year}</p>
              <div className="add-linkedIn">
                {profileUrls[index] && (
                  <a
                    href={profileUrls[index]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Add to LinkedIn
                  </a>
                )}
              </div>
            </div>
          </ClayLayout.Col>
        ))}
      </ClayLayout.Row>
    </ClayLayout.ContainerFluid>
  );
};

export default UserDashboardBadgeCard;
