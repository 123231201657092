import "../../index.css";
import ClayLayout from "@clayui/layout";
import { ClayTooltipProvider } from '@clayui/tooltip';
import { useEffect, useState } from "react";
import { getShortText } from "../../utils/util";
import { getCertificationsList } from "../../services/certification";

const Liferay = window.Liferay;

const getPersonas = (personaArray) => {
  let persona = '';

  if (personaArray) {
    persona = personaArray[0].name;

    if (personaArray.length > 1) {
      persona += '+' + (personaArray.length - 1);
    }
  }
  return persona;
}

const getTooltipPersona = (personaArray) => {
  let persona = "";

  personaArray.forEach((personas) => {
    persona += personas.name + ", ";
  });

  return persona.slice(0, -2);
};

const CertificationsList = () => {
  const [certifications, setCertifications] = useState(null);

  useEffect(() => {
    const fetchCertifications = async () => {
      const response = await getCertificationsList();

      const certifications = response.slice(0, 3);

      setCertifications(certifications);
    };

    fetchCertifications();
  }, []);

  return (
    <ClayLayout.ContainerFluid view>
      <ClayLayout.Row justify="start">
        {certifications && certifications.length > 0 && (
          <>
            {certifications.map((certification, index) => {
              return (
                <ClayLayout.Col
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  className="d-flex course-layout-col"
                  key={index}
                >
                  <a
                    href={`/l/${certification.id}`}
                    id="course-container-link"
                  >
                    <div className="course-container d-flex learn-education__landing-card">
                      <div className="course-content-card d-flex flex-column justify-content-between">
                        <h4 className="title">
                          {certification.title}
                        </h4>
                        <div className="description">
                          {getShortText(
                            certification.description, 200)}
                        </div>
                        <div
                          className="d-flex information learn-education"
                          key={certification.id}
                        >
                          <ClayTooltipProvider>
                            <div className="card-tag card-tag_persona" data-tool-tip-align="top" title={getTooltipPersona(certification.persona)}>
                              <p className="persona_card-tag_before"></p>
                              {getPersonas(certification.persona)}
                            </div>
                          </ClayTooltipProvider>
                        </div>
                      </div>
                    </div>
                  </a>
                </ClayLayout.Col>
              );
            })}
          </>
        )}
      </ClayLayout.Row>
    </ClayLayout.ContainerFluid>
  );
};

export default CertificationsList;