import React from "react";
import { enrollToCourseAndCompleteAsset } from "../../services/course";
import { updateCompletedAssets } from "../../services/enrollment";
import { getSignInPath, isSignedIn } from "../../utils/util";
import ClayIcon from "@clayui/icon";
import { t } from "i18next";

function CourseBottomNavigation({
  isEnrolled,
  courseId,
  currentQuizzId,
  completedAssetIds,
  linkPrefix,
  userEnrollmentId,
  prevLesson,
  nextLesson,
}) {
  const handleRegisterAndUpdateCompleteAssets = async () => {
    if (!isEnrolled) {
      await enrollToCourseAndCompleteAsset(courseId, currentQuizzId);
    }
  };

  const navigateToLesson = (lessonId) => {
    const url = `${linkPrefix && linkPrefix.length > 0 ? linkPrefix : "/l/"
      }${lessonId}`;

    window.location.href = url;
  };

  const handleUpdateCompletedAssets = async () => {
    const isLessonCompleted = completedAssetIds?.includes(currentQuizzId);

    if (isEnrolled && !isLessonCompleted) {
      await updateCompletedAssets(
        userEnrollmentId,
        completedAssetIds,
        currentQuizzId
      );
    }
  };

  const handleClickCompleteButton = async () => {
    if (isEnrolled) {
      await handleUpdateCompletedAssets();
    } else {
      if (isSignedIn()) {
        await handleRegisterAndUpdateCompleteAssets();
      }
    }

    if (nextLesson) {
      navigateToLesson(nextLesson.id);
    } else {
      window.location.href = "https://learn.liferay.com/education/index";
    }
  };

  return (
    <>
      <div onClick={handleClickCompleteButton}>
        <div className="course-nav-bottom__banner d-flex">
          <div className="banner-options d-flex">
            {nextLesson && nextLesson.id ? (
              <div className="banner-next-container mr-1">
                <ClayIcon className="mr-2" symbol="check-circle" />
                {t("mark-as-complete-and-continue")}
              </div>
            )
              : (
                <div className="banner-next-container mr-1">
                  <ClayIcon className="mr-2" symbol="check-circle" />
                  {t("mark-as-complete-and-return-home")}
                </div>

              )
            }

            {nextLesson && nextLesson.id && (
              <>
                <div className="banner-title">{nextLesson.title}</div>
                <div className="banner-icon">
                  <ClayIcon symbol="order-arrow-right"></ClayIcon>
                </div>
              </>
            )}
          </div>
        </div>

        {prevLesson && (
          <div className="course-nav-bottom__menu d-flex previous-lesson">
            <div
              className="menu-previous-lesson d-flex"
              onClick={() => {
                navigateToLesson(prevLesson.id);
              }}
            >
              <div className="previous-lesson-icon">
                <ClayIcon symbol="order-arrow-left"></ClayIcon>
              </div>
              <div className="previous-lesson-title">{prevLesson.title}</div>
              {!isSignedIn() && (
                <div className="sign-in-text">
                  <a href={getSignInPath()}>{t("sign-in")}</a>&nbsp;
                  {t("to-save-your-progress")}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CourseBottomNavigation;
