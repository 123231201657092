/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import "../styles/CertificationsDetails.css";
import { convertMinutesToDuration, getPersonas, getTooltipPersona } from "../utils/util";
import { useTranslation } from "react-i18next";
import { getAssessments, getLearningPathsIdFromAssessments, getCurrentCertification } from "../services/certification";
import { ClayTooltipProvider } from '@clayui/tooltip';

const CertificationsDetails = () => {
    const [assessments, setAssessments] = useState(null);
    const [certifications, setCertifications] = useState(null);
    const [expandedCards, setExpandedCards] = useState({});
    const [learningPaths, setLearningPaths] = useState([]);

    const {
        t,
      } = useTranslation();

    const toggleDescription = (index) => {
        setExpandedCards((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const previewText = (textDescription) => {
        return textDescription.substring(0, 50) + "...";
    } 

    useEffect(() => {
        const fetchAssessments = async () => {
            const response = await getAssessments();

            setAssessments(response);
        };

        const fetchCertifications = async () => {
            const response = await getCurrentCertification();

            setCertifications(response);
        };

        const fetchLearningPathsId = async () => {
            const response = await getLearningPathsIdFromAssessments();

            setLearningPaths(response);
        };

        fetchAssessments();

        fetchCertifications();

        fetchLearningPathsId();

    }, []);

    return (
        <>
            <div className="certification-detail-banner">
                <div className="lp-banner-contents">
                <div className="certification-detail-tag">
                    <p>{t("certification")}</p>
                </div>

                <h1>{certifications?.name}</h1>

                <div className="certification-detail-duration-persona">

                    <div className="certification-detail-info-tag d-flex mt-3">
                    {certifications &&
                        <ClayTooltipProvider>
                            <div className="certification-detail-banner-persona" data-tool-tip-align="top" title={getTooltipPersona(certifications.persona)}>
                                <p></p>
                                {getPersonas(certifications.persona)}
                            </div>
                        </ClayTooltipProvider>
                    }
                    </div>
                </div>
                </div>
            </div>

            <div className="certifications-details-main">
                <div className="certifications-details-title">
                    <h1>{t("required-assessments")}</h1>
                    <p>{t("description-assessments-certifications")}</p>
                </div>

                {assessments && assessments.length > 0 && learningPaths && learningPaths.length > 0 && (
                    <>
                        {assessments.map((assessment, index) => (
                    
                            <li key={assessment.id}>
                                
                                <div className="certifications-details-card">
                                    <div className="tag-duration-certifications">
                                        <p>
                                            {convertMinutesToDuration(assessment.durationMinutes, "hours")}
                                        </p>
                                    </div>
                                    <h2>{assessment.title}</h2>
                                    <div>
                                        <div className="description-card-certifications-details">
                                            <p>
                                                {expandedCards[index]
                                                    ? assessment.description
                                                    : previewText(assessment.description)}
                                            </p>
                                        </div>
                                        <p onClick={() => toggleDescription(index)}
                                            style={{
                                                    cursor: "pointer",
                                                    fontWeight: 600,
                                                    textDecoration: "underline",
                                                    }}
                                        >
                                            {expandedCards[index] ? "Show Less" : "Show More"}
                                        </p>
                                    </div>

                                    <div className="buttons-card-certifications-details">
                                        {learningPaths.map((learningPath) => {
                                            if(learningPath.r_p2S3AssessmentToP2S3LearningPaths_c_p2s3AssessmentId > 0 &&
                                                learningPath.r_p2S3AssessmentToP2S3LearningPaths_c_p2s3AssessmentId === assessment.id) {

                                                return ( 
                                                    <a
                                                        key={learningPath.id}
                                                        href={`/l/${learningPath.id}`} 
                                                        className="button-learning-path"
                                                    >

                                                        {assessment.title} 
                                                        {t("learning-path")}
                                                    </a>
                                                );
                                            }
                                        })}
                                        <a href={assessment.uRL} className="button-take">
                                            <span>{t("take")} {assessment.title}</span>
                                        </a>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </>
                )}
            </div>
        </>
    );
}

export default CertificationsDetails;