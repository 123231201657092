import { useCallback, useEffect, useState } from "react";
import ClayList from "@clayui/list";
import { Text } from "@clayui/core";

const ArticleNavigation = (() => {
    const [headings, setHeadings] = useState([]);
    const [selectedAnchorId, setSelectedAnchorId] = useState("");
    const [effectActive, setEffectActive] = useState(true);

    useEffect(() => {
        const elements = Array.from(document.querySelectorAll("h2"))
          .filter((element) => element.id)
          .map((element) => ({
            id: element.id,
            text: element.textContent ?? "",
          }));
        setHeadings(elements);
      }, []);

    const scrollToPageElement = useCallback((elementId) => {

        const element = document.getElementById(elementId);
        if (element) {
            const { top } = element.getBoundingClientRect();
            
            const scrollPosition = top + window.scrollY - 190;

            window.scrollTo({
                top: scrollPosition,
                behavior: "smooth",
            });

            setSelectedAnchorId(elementId);
            element.focus({ preventScroll: true });
        }
    }, []);

    useEffect(() => {
        if (!effectActive) {
            return
        }
        const handleScroll = () => {
            const elements = document.querySelectorAll("h2");
    
            let closestElement = null;
            let closestOffset = Number.POSITIVE_INFINITY;
    
            elements.forEach((element) => {
                const { top } = element.getBoundingClientRect();
    
                if (top - 150 >= 0 && top - 150 < closestOffset) {
                    closestOffset = top -100;
                    closestElement = element;
                }
            });
    
            if (closestElement) {
                setSelectedAnchorId(closestElement.id);
            }
        };
    
        window.addEventListener("scroll", handleScroll);
    
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [effectActive]);

    const handlerScrollToPageElement = (async(id) => {
        setEffectActive(false)
        scrollToPageElement(id)
        await new Promise(resolve => setTimeout(resolve, 650));
        setEffectActive(true)
    })

      return (
        <div  className={`article-navigation ${headings.length > 0 ? "show" : "hide"}`}>
            {headings.length > 0 && (
                <ClayList>
                    {headings.map((anchorElement) => (
                        <ClayList.Item
                            className={`border-0 article-nav-element ${
                                selectedAnchorId === anchorElement.id ? "active" : ""}`}
                        >   
                                <ClayList.ItemText
                                    style={{ cursor: "pointer"}}
                                    onClick={() => handlerScrollToPageElement(anchorElement.id)}
                                >
                                    <Text size={4} weight="bold">{anchorElement.text}</Text>
                                </ClayList.ItemText>
                        </ClayList.Item>
                    ))}
                </ClayList>
            )}
        </div>
    );
});

export default ArticleNavigation;
