import {useCallback, useEffect, useRef, useState} from "react";
import {request} from "../utils/request";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const endPointConfig = {
    "com.liferay.headless.admin.list.type.dto.v1_0.ListTypeDefinition": "/o/headless-admin-list-type/v1.0/list-type-definitions/batch",
    "com.liferay.object.admin.rest.dto.v1_0.ObjectFolder": "/o/object-admin/v1.0/object-folders/batch",
    "com.liferay.object.admin.rest.dto.v1_0.ObjectDefinition": "/o/object-admin/v1.0/object-definitions/batch",
    "com.liferay.object.admin.rest.dto.v1_0.ObjectRelationship": "/o/object-admin/v1.0/object-definitions/__ID__/object-relationships/batch",
    "com.liferay.object.admin.rest.dto.v1_0.ObjectField": "/o/object-admin/v1.0/object-definitions/__ID__/object-fields/batch",
    "com.liferay.object.admin.rest.dto.v1_0.ObjectLayout": "/o/object-admin/v1.0/object-definitions/__ID__/object-layouts/batch",
    "com.liferay.object.admin.rest.dto.v1_0.ObjectView": "/o/object-admin/v1.0/object-definitions/__ID__/object-views/batch",
    "object.definition.get": "/o/object-admin/v1.0/object-definitions/by-external-reference-code/",
    "import.task":"/o/headless-batch-engine/v1.0/import-task/"
}

const importAndSortFiles = (context) => {
    const files = context.keys().map((key) => ({
        name: key,
        content: context(key),
    }));

    return files.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // Ignore upper/lower case
        const nameB = b.name.toUpperCase(); // Ignore upper/lower case

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // Names must be equal
        return 0;
    });
};

const handleBatch = async (batchFile)=>{

    let apiURL = endPointConfig[batchFile.configuration.className];

    if ( batchFile.configuration.parameters && batchFile.configuration.parameters.externalReferenceCode){

        let objectDefinitionERC = batchFile.configuration.parameters.externalReferenceCode;

        let objectDefinition = await request({
            url:`${endPointConfig["object.definition.get"]}${objectDefinitionERC}`,
            method:'get',
        });

        apiURL = apiURL.replaceAll("__ID__", objectDefinition.id);

    }

    return request({
        url:apiURL,
        method:"POST",
        data:batchFile.items
    })

}

const taskStatus = async (taskId) => {
    return request({
        url:`${endPointConfig["import.task"]}/${taskId}`,
        method:"get"
    });
}

const CreateObjects = () => {

    const [files, setFiles] = useState([]);

    const [log, setLog] = useState([]);

    const logContainerRef = useRef(null);

    useEffect(() => {
        if (logContainerRef.current) {
            logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
        }
    }, [log]);

    const startBatchJob = useCallback(async () => {

        for (let index = 0 ; index < files.length; index++) {
            try{
                setLog(log=>[...log,`Processing ${files[index].name}`]);

                let task = await handleBatch(files[index].content)

                setLog(log=>[...log,`Processing ${files[index].name} completed!`]);

                await sleep(1000);

                let _taskStatus = await taskStatus(task.id);

                while(true){

                    if(_taskStatus.executeStatus === "COMPLETED"){

                        break;
                    }

                    if (_taskStatus.executeStatus === "FAILED"){
                        setLog(log=>[...log,`Error while processing ${files[index].name}, Batch Process ID: ${_taskStatus.id}`]);

                        throw  new Error(`Error while processing ${files[index].name}, Batch Process ID: ${_taskStatus.id}`);
                    }

                    await sleep(1000);

                    _taskStatus = await taskStatus(task.id);

                }

            }catch (error){
                setLog(log=>[...log,`Error while processing ${files[index].name}`]);
                console.error(error.message);
            }
        }

    },[files])

    useEffect(() => {

        const dataFiles = importAndSortFiles(require.context('../batch', false, /\.json$/));

        setFiles(dataFiles);

    }, []);

    return (
        <>
            {files && (
                <>
                    <button onClick={startBatchJob}>Create Objects</button>
                    <div
                        ref={logContainerRef}
                        className="container-fluid bg-dark text-light w-100"
                        style={{ height: "50vh", overflowY: "scroll" }}
                    >
                        {log && log.map((logItem, index) => <div key={index}>{logItem}</div>)}
                    </div>
                </>
            )}
        </>
    );

}


export default CreateObjects;
