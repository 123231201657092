import React, { useEffect, useState } from "react";
import { getCurrentUserId, splitStringtoArray } from "../../utils/util";
import { getBadgeById, getUserBadgesByUserId } from "../../services/badge";
import { getAllCoursesByUserEnrollments } from "../../services/enrollment";
import {
  getAllCoursesAndFirstLessons,
  getCourseByAssetTypeAndId,
} from "../../services/course";
import "../../index.css";
import UserDashboardCourseCard from "./UserDashboardCourseCard";
import UserDashboardSectionBanner from "./UserDashboardSectionBanner";
import UserDashboardEmptySection from "./UserDashboardEmptySection";
import UserDashboardBadgeCard from "./UserDashboardBadgeCard";

const UserDashboardView = () => {
  const [userCompleteEnrollments, setUserCompleteEnrollments] = useState([]);
  const [userInProgressEnrollments, setUserInProgressEnrollments] = useState(
    []
  );
  const [userBadges, setUsersBadges] = useState([]);

  useEffect(() => {
    const fetchUserBadges = async () => {
      const data = await getUserBadgesByUserId(getCurrentUserId());

      const userBadgesList = await Promise.all(
        data.items.map(async (item) => {
          const badgeCourse = await getCourseByAssetTypeAndId(
            "quiz",
            item.quizId
          );
          const userBadge = await getBadgeById(item.badgeId);

          return {
            course: badgeCourse,
            image: userBadge.badge.link.href,
            title: userBadge.title,
            year: userBadge.year,
          };
        })
      );
      const filteredUserBadgesList = userBadgesList.filter(
        (badge) => badge !== null
      );

      setUsersBadges(filteredUserBadgesList);
      return filteredUserBadgesList;
    };

    const fetchUserEnrollments = async (filteredUserBadgesList) => {
      const activeCourses = await getAllCoursesByUserEnrollments();

      const passedQuizCourses = filteredUserBadgesList
        .filter((item) => item.course)
        .map((item) => item.course);

      const data = await getAllCoursesAndFirstLessons();

      const coursesFirstLessonList = data
        .filter((course) => course.lesson && course.r_module_c_course)
        .map((course) => ({
          [course.r_module_c_course.id]: course.lesson[0].id,
        }));

      const findEnrolledCourseFirstLesson = (
        courseId,
        coursesFirstLessonList
      ) => {
        const courseFirstLesson = coursesFirstLessonList.find(
          (obj) => Object.keys(obj)[0] === courseId.toString()
        );
        return courseFirstLesson ? Object.values(courseFirstLesson)[0] : null;
      };

      const userEnrolledCourseList = await Promise.all(
        activeCourses.items.map(async (item) => {
          const firstLesson = findEnrolledCourseFirstLesson(
            item.r_courseEnrollment_c_courseId,
            coursesFirstLessonList
          );

          const completedAssets = splitStringtoArray(item.completedAssetIds);

          const isCompletedCourseByAssets =
            completedAssets.length >=
            item.r_courseEnrollment_c_course.totalAssets;

          const isCompletedCourseByQuiz = passedQuizCourses.includes(
            item.r_courseEnrollment_c_courseId
          );

          const isCourseComplete =
            isCompletedCourseByAssets || isCompletedCourseByQuiz;

          const courseProgress =
            completedAssets[0] === 0
              ? 0
              : parseInt(
                  (
                    (completedAssets.length /
                      item.r_courseEnrollment_c_course.totalAssets) *
                    100
                  ).toFixed(0)
                );

          return {
            courseComplete: isCourseComplete,
            courseProgress: courseProgress,
            description: item.r_courseEnrollment_c_course.description,
            firstLesson: firstLesson,
            id: item.r_courseEnrollment_c_courseId,
            title: item.r_courseEnrollment_c_course.title,
          };
        })
      );

      setUserCompleteEnrollments(
        userEnrolledCourseList.filter((item) => item.courseComplete)
      );
      setUserInProgressEnrollments(
        userEnrolledCourseList.filter((item) => !item.courseComplete)
      );
    };

    const fetchData = async () => {
      const filteredUserBadgesList = await fetchUserBadges();
      fetchUserEnrollments(filteredUserBadgesList);
    };
    fetchData();
  }, []);

  return (
    <div className="user-dashboard">
      <UserDashboardSectionBanner section="inprogress" />
      {userInProgressEnrollments.length > 0 ? (
        <UserDashboardCourseCard userEnrollments={userInProgressEnrollments} />
      ) : (
        <UserDashboardEmptySection section="inprogress-no-courses" />
      )}

      <UserDashboardSectionBanner section="completed" />
      {userCompleteEnrollments.length > 0 ? (
        <UserDashboardCourseCard userEnrollments={userCompleteEnrollments} />
      ) : (
        <UserDashboardEmptySection section="completed-no-courses" />
      )}

      <UserDashboardSectionBanner section="badges" />
      {userBadges.length > 0 ? (
        <UserDashboardBadgeCard userBadges={userBadges} />
      ) : (
        <UserDashboardEmptySection section="no-badges" />
      )}
    </div>
  );
};

export default UserDashboardView;
