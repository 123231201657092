import {request} from "../utils/request";


const DeleteObjects = ()=>{

    const deleteObjRel = (id)=>{
        return request({
            url:`/o/object-admin/v1.0/object-relationships/${id}`,
            method: "delete"
        })
    }

    const deleteObj = (id)=>{
        return request({
            url:`/o/object-admin/v1.0/object-definitions/${id}`,
            method: "delete"
        })
    }

    const deleteObjectFolder = (id)=>{
        return request({
            url:`/o/object-admin/v1.0/object-folders/${id}`,
            method: "delete"
        })
    }

    const deleteListTypeDefinition = (id)=>{
        return request({
            url:`/o/headless-admin-list-type/v1.0/list-type-definitions/${id}`,
            method: "delete"
        })
    }

    const handleDelete = () =>{
        try {
            request({
                url :'/o/object-admin/v1.0/object-folders/by-external-reference-code/T4T14_LMS_FOLDER',
                method:'get'
            }).then(async result => {
    
                for (const element of result.objectFolderItems) {
                    if (element.objectDefinition) {
                        const {objectDefinition} = element;
                        const {id, objectRelationships,name} = objectDefinition;
    
                        if (objectRelationships && objectRelationships.length > 0) {
                            for (let index =0 ; index < objectRelationships.length ; index++){
                                try {
                                    await deleteObjRel(objectRelationships[index].id);
                                }
                                catch (error){
                                    console.error(error.message);
                                }
                            }
                        }
                    }
                }
    
                for (const element of result.objectFolderItems) {
                    if (element.objectDefinition) {
                        const {objectDefinition} = element;
                        const {id, objectRelationships,name} = objectDefinition;
                        await deleteObj(id);
                    }
                }
    
                deleteObjectFolder(result.id);
            });
        }
        catch(error) {
            // console.error(error.message);
        }

        try {
            const listTypeDefinitionExternalReferenceCodes =
                ['T4T14_ACCESS_TYPE', 'EXPERTISE_LEVELS', 'T4T14_PERSONAS',
                'T4T14_QUIZ_QUESTION_TYPE', 'T4T14_STEP_TYPE'];
    
                listTypeDefinitionExternalReferenceCodes.forEach((item, index) => {
                    request({
                        url :`/o/headless-admin-list-type/v1.0/list-type-definitions/by-external-reference-code/${item}`,
                        method:'get'
                    }).then(async result => {
                        console.log('RESULT: ', result);

                        deleteListTypeDefinition(result.id);
                    });
                });
        }
        catch(error) {
            console.error(error.message);
        }
    }

    return <button onClick={handleDelete}>Delete Objects</button>
}

export default DeleteObjects;
