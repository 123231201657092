/* global Liferay */
import ClayButton from "@clayui/button";
import ClayIcon from "@clayui/icon";
import React, { useEffect, useState } from "react";
import { getDPTAssetId } from "../../utils/util";
import { isSignedIn, getSignInPath, getCreateAccountPath } from "../../utils/util";
import { useTranslation } from "react-i18next";
import "../../styles/QuizResultSheet.css";
import { updateCompletedAssets } from "../../services/enrollment"
import useLinkedInProfile from "../Hooks/UseLinkedInButton";

const QuizResultSheet = ({
  isEnrolled,
  currentQuizzId,
  userEnrollmentId,
  completedAssetIds,
  scoreSheetOverall,
  badgePageUrl,
  educationPageUrl,
  handleReset,
  isKnowledgeCheck,
  questions,
  currentBadge,
}) => {
  const {
    passed,
    passingScore,
    totalScore,
    title,
    totalPassedQuestions,
    totalQuestions
  } = scoreSheetOverall;

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [moduleInfo, setModuleInfo] = useState({});
  const { profileUrls } = useLinkedInProfile();

  if (passed) {
    const handleUpdateCompletedAssets = async () => {
      const isQuizCompleted = completedAssetIds?.includes(currentQuizzId)
      
      if (isEnrolled && !isQuizCompleted) {
        await updateCompletedAssets(userEnrollmentId, completedAssetIds, currentQuizzId);
      }
    };

    handleUpdateCompletedAssets()
  }

  useEffect(() => {
    if (isKnowledgeCheck) {
      const fetchData = async () => {
        Liferay.Util.fetch(
          `/o/c/modules/scopes/${Liferay.ThemeDisplay.getScopeGroupId()}?filter=quiz%2Fid%20eq%20%27${getDPTAssetId()}%27`
        )
          .then((response) => response.json())
          .then((response) => {
            setModuleInfo({
              position: response.items[0].position,
              title: response.items[0].title,
            });
          });
      };
      fetchData().catch(console.error);
    }
  }, [isKnowledgeCheck]);

  return (
    <>
      {scoreSheetOverall && isSignedIn() && !isKnowledgeCheck && (
        <div className="container-quiz-result">
          <div className="quiz-result-sheet">
            <div className="content-result">
              <h3>
                {passed ? `${t("congratulations")} 🎉` : `${t("failed-title")} \u{1F61E}`}{" "}
              </h3>
              <div className="text-quiz-result">
                <p>
                  {passed
                    ? t("congratulations-text", { courseTitle: title })
                    : t("failed-message", { passingScore: passingScore })}
                </p>
              </div>
            </div>

            <div className="content-score">
              <p>{t("your-score")} </p>
              <div className="ponctuation">
                <span className={"d-flex"}>
                  <span className={"d-flex"}>
                    <h3 className={"m-auto"}>
                      {t("score-break-down", {
                        totalCorrect: totalPassedQuestions,
                        totalCount: totalQuestions,
                        score: totalScore,
                      })}
                    </h3>
                  </span>
                  {passed && (
                    <span className="pass-tag d-flex">
                      <span className={"m-auto"}>
                        <span className="circle">
                          <span>✔</span>
                        </span>
                        <span>{t("pass")}</span>
                      </span>
                    </span>
                  )}
                </span>
              </div>
              {!passed && (
                <div className="quiz-retake my-2">
                  <ClayButton onClick={handleReset}>
                    {t("try-again")}

                    <span className="inline-item inline-item-before mx-2">
                      <ClayIcon symbol="reload" />
                    </span>
                  </ClayButton>
                </div>
              )}
            </div>
          </div>
          {passed && (
            <div className="container-badges">
              <div className="badges-content">
              {currentBadge && (
                  <div>
                    <img
                      alt={currentBadge.title}
                      src={currentBadge.badge.link.href}
                    />
                  </div>
              )}

                <div className="see-badges">
                  <a href={badgePageUrl}>{t("see-my-badges")} →</a>
                </div>
                <div className="add-linkedIn">
                {profileUrls && (
                  <a
                    href={profileUrls}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Add to LinkedIn
                  </a>
                )}
              </div>
              </div>
              <a href={educationPageUrl}>
                {t("return-to-education-home-page")}
              </a>
            </div>
          )}
        </div>
      )}

      {isKnowledgeCheck && (
        <>
          <div className="card-kc-finish">
            <h2>{t("knowledge-check-results")}</h2>

            <li>
              <div className="info-card-kc">
                <div className="completed-message">
                  {t("you-completed-knowledge-check", {
                    position: `${moduleInfo.position}`,
                    title: `${moduleInfo.title}`,
                  })}
                </div>

                <div className="score-content">
                  <p>{t("your-score")}</p>
                  <div>
                    <h4>{totalPassedQuestions}</h4>{" "}
                    <p>
                      <b>{t("of")}</b>
                    </p>{" "}
                    <h4>{totalQuestions}</h4>
                  </div>
                </div>
              </div>
            </li>
          </div>

          <div className="container-questions">
            <h3>{t("questions")}</h3>
            {Object.keys(questions).map((time) => (
              <li className="answers-content" key={questions[time].id}>
                {questions[time].type == "selectSingleChoice" && (
                  <div>
                    {scoreSheetOverall.scoreSheet[time].achievedScore >=
                    scoreSheetOverall.scoreSheet[time].totalScore ? (
                      <div className="title-question">
                        <div className="circle-title circle-right">
                          {questions[time].position}
                        </div>
                        <p>{questions[time].title}</p>
                      </div>
                    ) : (
                      <div className="title-question">
                        <div className="circle-title circle-wrong">
                          {questions[time].position}
                        </div>
                        <p>{questions[time].title}</p>
                      </div>
                    )}

                    <div className="answer-container">
                      {Object.keys(questions[time].answers).map((k) => (
                        <li key={questions[time].answers[k].id}>
                          {scoreSheetOverall.scoreSheet[time].selectedAnswer ==
                          questions[time].answers[k].id ? (
                            <div>
                              {questions[time].answers[k].score > 0 ? (
                                <div className="allquestion">
                                  {" "}
                                  <input
                                    className="circle-question-right"
                                    disabled
                                  ></input>{" "}
                                  <p>{questions[time].answers[k].title}</p>{" "}
                                </div>
                              ) : (
                                <div className="allquestion">
                                  {" "}
                                  <input
                                    className="circle-question-wrong"
                                    disabled
                                  ></input>{" "}
                                  <p>{questions[time].answers[k].title}</p>{" "}
                                  <p className="wrong-answer">
                                    {t("your-answer-2")}
                                  </p>{" "}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              {questions[time].answers[k].score > 0 ? (
                                <div className="allquestion">
                                  {" "}
                                  <input
                                    className="circle-question"
                                    disabled
                                  ></input>{" "}
                                  <p>{questions[time].answers[k].title}</p>{" "}
                                  <p className="correct-answer">
                                    {t("correct-answer")}
                                  </p>{" "}
                                </div>
                              ) : (
                                <div className="allquestion">
                                  {" "}
                                  <input
                                    className="circle-question"
                                    disabled
                                  ></input>{" "}
                                  <p>{questions[time].answers[k].title}</p>{" "}
                                </div>
                              )}
                            </div>
                          )}
                        </li>
                      ))}
                    </div>
                  </div>
                )}

                {questions[time].type == "selectMultipleChoice" && (
                  <>
                    {scoreSheetOverall.scoreSheet[time].achievedScore >=
                    scoreSheetOverall.scoreSheet[time].totalScore ? (
                      <div className="title-question">
                        <div className="circle-title circle-right">
                          {questions[time].position}
                        </div>
                        <p>{questions[time].title}</p>
                      </div>
                    ) : (
                      <div className="title-question">
                        <div className="circle-title circle-wrong">
                          {questions[time].position}
                        </div>
                        <p>{questions[time].title}</p>
                      </div>
                    )}

                    <div className="answer-container">
                      {Object.entries(
                        scoreSheetOverall.scoreSheet[time].selectedAnswer
                      ).map(([key, value]) => (
                        <li key={key}>
                          {Object.keys(questions[time].answers).map((i) => (
                            <li key={questions[time].answers[i].id}>
                              {questions[time].answers[i].id == key ? (
                                <>
                                  {value &&
                                    questions[time].answers[i].score > 0 && (
                                      <div className="allquestion">
                                        {" "}
                                        <div className="square-check-correct"></div>{" "}
                                        {questions[time].answers[i].title}{" "}
                                      </div>
                                    )}

                                  {value &&
                                    questions[time].answers[i].score <= 0 && (
                                      <div className="allquestion">
                                        {" "}
                                        <div className="square-check-wrong"></div>{" "}
                                        {questions[time].answers[i].title}{" "}
                                        <p className="wrong-answer">
                                          {t("your-answer-2")}
                                        </p>
                                      </div>
                                    )}

                                  {!value &&
                                    questions[time].answers[i].score > 0 && (
                                      <div className="allquestion">
                                        {" "}
                                        <div className="square-check"></div>
                                        {questions[time].answers[i].title}{" "}
                                        <p className="correct-answer">
                                          {t("correct-answer")}
                                        </p>
                                      </div>
                                    )}

                                  {!value &&
                                    questions[time].answers[i].score <= 0 && (
                                      <div className="allquestion">
                                        {" "}
                                        <div className="square-check"></div>
                                        {questions[time].answers[i].title}{" "}
                                      </div>
                                    )}
                                </>
                              ) : (
                                <></>
                              )}
                            </li>
                          ))}
                        </li>
                      ))}
                    </div>
                  </>
                )}
              </li>
            ))}
          </div>
        </>
      )}

      {scoreSheetOverall && !isSignedIn() && !isKnowledgeCheck && (
        <div className="container-quiz-result">
          <div className="quiz-result-sheet">
            <div className="content-result">
              <h3>
                {passed
                  ? `${t("congratulations")} 🎉`
                  : `${t("failed-title")} \u{1F61E}`}{" "}
              </h3>
              <div className="text-quiz-result">
                <p>
                  {passed ? (
                    <>
                      {t("you-passed")}{" "}
                      <a href={getSignInPath()}>{t("sign-in")}</a>{" "}
                      {t("retake-exam")}
                    </>
                  ) : (
                    <>
                      {t("failed-message-unassigned", {
                        passingScore: passingScore,
                      })}{" "}
                      <a href={getSignInPath()}>{t("log-in")}</a> {t("or")}{" "}
                      <a href={getCreateAccountPath()}>{t("create-account")}</a>{" "}
                      {t("failed-unassigned")}
                    </>
                  )}
                </p>
              </div>
            </div>

            <div className="content-score">
              <p>{t("your-score")} </p>
              <div className="ponctuation">
                <span className={"d-flex"}>
                  <span className={"d-flex"}>
                    <h3 className={"m-auto"}>
                      {t("score-break-down", {
                        totalCorrect: totalPassedQuestions,
                        totalCount: totalQuestions,
                        score: totalScore,
                      })}
                    </h3>
                  </span>
                  {passed && (
                    <span className="pass-tag d-flex">
                      <span className={"m-auto"}>
                        <span className="circle">
                          <span>✔</span>
                        </span>
                        <span>{t("pass")}</span>
                      </span>
                    </span>
                  )}
                </span>
              </div>
              {!passed && (
                <div className="quiz-retake my-2">
                  <ClayButton onClick={handleReset}>
                    {t("try-again")}

                    <span className="inline-item inline-item-before mx-2">
                      <ClayIcon symbol="reload" />
                    </span>
                  </ClayButton>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuizResultSheet;